.parallaxSaltburn {
    background-image: url("../resources/images/quaid-lagan-OPYDe-bOGzs-unsplash.png");
    background-position:center;
    background-attachment:fixed;
    -o-background-size:cover;
    -moz-background-size:cover;
    -webkit-background-size:cover;
    background-size:cover;
    background-repeat:no-repeat;
}
.parallax {
    width: 100vw !important;
    background-position:center;
    background-attachment:fixed;
    background-size:cover;
    background-repeat:no-repeat;
}

.parallaxWaves {
    background-image: url("../resources/images/waves.png");
    height: 100%;
    width: 100vw !important;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.parallaxPlain {
    background-image: url("../resources/images/plant.jpeg");
    height: 100%;
    width: 100% !important;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.parallaxSunflowers {
    background-image: url("../resources/images/sunflowers.jpeg");
    height: 100%;
    width: 100vw !important;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallaxMountain {
    background-image: url("../resources/images/mountains.jpeg");
    height: 100%;
    width: 100vw !important;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallaxHalfGrey {
    background-image: url("../resources/images/halfgrey.jpeg");
    height: 100%;
    width: 100vw !important;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallaxMarble {
    background-image: url("../resources/images/marble.png");
    height: 100%;
    width: 100vw !important;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}
