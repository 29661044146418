.height100 {
    height: 100%;
}

.h-45vw {
    height: 50vw !important;
}
.h-60vw {
    height: 80vw !important;
}
.h-100vw {
    height: 100% !important;
}
.h-30vw {
    height: 30vw !important;
}
.w-300 {
    width: 300px !important;
}
.h-100 {
    height: 100px;
}
.h-350 {
    height: 350px !important;
}
.h-150 {
    height: 150px !important;
}
.h-50 {
    height: 50px !important;
}
.h-200 {
    height: 200px;
}
.h-2000 {
    height: 2000px;
}.h-1500 {
    height: 1500px;
}
.h-1000 {
    height: 1000px;
}
.h-150 {
    height: 150px;
}
.h-600 {
    height: 600px;
}
.h-800 {
    height: 800px;
}
.w-35vw{
    width: 35vw;
    align-content: center;
}
.width100{
    width: 100% !important;
}
.width100vw{
    width: 100vw !important;
}
