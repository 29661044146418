.CormorantGaramond {
    font-family: 'CormorantGaramond', serif !important;
    color: black;
    font-size: 1.8vw;
}

.CormorantGaramondWithoutSize {
    font-family: 'CormorantGaramond', serif !important;
    color: black;
    letter-spacing: 4.6px;
    word-break: break-word;
}
.CormorantGaramondWithoutSizeAndSpacing {
    font-family: 'CormorantGaramond', serif !important;
    color: black;
}


.ibarraSize22{
    font-family: 'Ibarra', serif !important;
    color: black;
    font-size: 2vw;
    letter-spacing: 4.6px;
}
.ibarra{
    font-family: 'Ibarra', serif !important;
    letter-spacing: 4.6px;
}

.playfair_Display {
    font-family: 'Playfair_Display', serif !important;
}
.nixie{
    font-family: 'Nixie_One', serif !important;
}

@font-face {
    font-family: 'CormorantGaramond';
    src: url('../fonts/Cormorant_Garamond/CormorantGaramond-Light.ttf') format("truetype");
}
@font-face {
    font-family: 'Ibarra';
    src: url('../fonts/Ibarra_Real_Nova/IbarraRealNova-Regular.ttf') format("truetype");
}
@font-face {
    font-family: 'BethEllen';
    src: url('../fonts/Beth_Ellen/BethEllen-Regular.ttf') format("truetype");
}
@font-face {
    font-family: 'Amatic_SC';
    src: url('../fonts/Amatic_SC/AmaticSC-Bold.ttf') format("truetype");
}
@font-face {
    font-family: 'Playfair_Display';
    src: url('../fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf') format("truetype");
}
@font-face {
    font-family: 'Calligraffitti';
    src: url('../fonts/Calligraffitti/Calligraffitti-Regular.ttf') format("truetype");
}
@font-face {
    font-family: 'Nixie_One';
    src: url('../fonts/Nixie_One/NixieOne-Regular.ttf') format("truetype");
}

