.background {
    background-color: white;
    width: 100vw;
}
.viaducts {
     max-width: 120% !important;
     height: auto !important;
     background-color: rgba(0,0,0,0);
     display: flex;
 }
.avatar{
    height: 450px !important;
    width: 350px !important;
}
.avatar-mobile{
    height: 200px !important;
    width: 150px !important;
}
.my-footer{
    background-color: #f5f3f2;
    width: 100vw;
}
.card {
    text-align: center;
}
.carousel-my{
    margin-left: 15vw !important;
}
.no-background {
    background-color: rgba(0, 0, 0, 0) !important;
}
.numbered-list {
    list-style: decimal;
}
.card-image {
    flex-shrink: 20 !important;
    width: 100%;
    height: 100% !important;
    align-self: flex-start;
    rotation: 90deg;
}
.nav {
    font-family: 'Ibarra', serif !important;
    color: black;
    font-size: 1.4vw;
}
.nav-width{
    width: 100vw !important;
    padding: 0 !important;
}
.page {
    position: absolute;
    top: 0;
    width: 100vw;
}
.logo {
    margin: 0 !important;
    padding: 0 !important;
    height: 60px;
}

.text-padding{
    padding-left: 4vw;
    padding-right: 4vw;
}

.back-img{
    background-image: url("../resources/images/landscape.jpeg");
    background-size: cover;
}
.inline{
    color: #F9EBEA !important;
}