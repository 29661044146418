.pointer {
    cursor: pointer;
}

.nav-link {
    color: rgba(0,0,0,.5);
    font-family: 'Playfair_Display' !important;

}

.nav {
    --bs-nav-link-hover-color: black !important;
    color: rgba(0,0,0,.5);
    font-size: 24px;
}