
.no-margin {
    border-width: 0px 0px 0px 0px !important;
}
.align-left {
    margin-left: 100px;
    position: absolute;
}
.text-left {
    text-align: left;
    width: 100%;
    margin-left: 1vw !important;
}
.center {
    align-items: center !important;
    justify-content: center !important;
}
.topCenter {
    align-items: center !important;
    justify-content: center !important;
    vertical-align: top !important;
}
.right {
    align-items: end !important;
    justify-content: right !important;
}

.margin-left{
    margin-left: 75px !important;
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.word-breaks {
    word-break: break-word !important;
}