body {
    display: flex;
    overflow-x: auto;
    white-space: normal;
    padding: 0;
    margin: 0;
}
.background {
    background-color: white;
}
h4 {
    display: inline;
    font-family: "Times New Roman", serif;
}

.no-margin {
    border-width: 0px 0px 0px 0px !important;
}

.width100 {
    width: 100vw;
    border-width: 0;
}
.viaducts {
     max-width: 120% !important;
     height: auto !important;
     background-color: rgba(0,0,0,0);
     display: flex;
 }
.avatar{
    height: 450px !important;
    width: 350px !important;
}
.align-left {
    margin-left: 100px;
    position: absolute;
}

.height100 {
    height: 100%;
}

.my-footer{
    background-color: #f5f3f2;
}

.card-image {
    flex-shrink: 20 !important;
    width: 100%;
    height: 100% !important;
    align-self: flex-start;
    rotation: 90deg;
}

.h-45vw {
    height: 50vw !important;
}
.h-30vw {
    height: 30vw !important;
}
.w-300 {
    width: 300px !important;
}

.h-100 {
    height: 100px;
}
.h-2000 {
    height: 2000px;
}

.card {
    text-align: center;
}

.text-left {
    text-align: left;
    width: 100%;
    margin-left: 1vw !important;
}

.carousel-my{
    margin-left: 15vw !important;
}

.no-background {
    background-color: rgba(0, 0, 0, 0) !important;
}

.center {
    justify-content: center !important;
}

.margin-left{
    margin-left: 75px !important;
}

.numbered-list {
    list-style: decimal;
}
.calendly-badge-widget { left: 50% !important; margin-left: -100px!important; }

p {
    margin: 0 !important;
}

.w-35vw{
    width: 35vw;
    align-content: center;
}


.page {
    position: absolute;
    top: 0;
}

.logo {
    margin: 0 !important;
    padding: 0 !important;
    height: 60px;
}

.box {
    border-color: rgba(0, 0, 0, 0) !important;
    position: relative;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: 0px 0px 0px;
    transition: all 0.3s ease-in-out;
}

.box::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 0px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
}

.box:hover {
    transform: scale(1.2, 1.2);
}

.box:hover::after {
    opacity: 1;
}

.parallaxSaltburn {
    background-image: url("resources/images/quaid-lagan-OPYDe-bOGzs-unsplash.png");
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallaxWaves {
    background-image: url("resources/images/waves.png");
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.parallaxPlain {
    background-image: url("resources/images/leafy.jpeg");
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallaxSunflowers {
    background-image: url("resources/images/sunflowers.jpeg");
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallaxMountain {
    background-image: url("resources/images/mountains.jpeg");
    height: 100%;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallaxHalfGrey {
    background-image: url("resources/images/halfgrey.jpeg");
    height: 100%;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallaxMarble {
    background-image: url("resources/images/marble.png");
    height: 100%;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.CormorantGaramond {
    font-family: 'CormorantGaramond', serif !important;
    color: black;
    font-size: 1.8vw;
}

.CormorantGaramondWithoutSize {
    font-family: 'CormorantGaramond', serif !important;
    color: black;
    letter-spacing: 4.6px;
}

.ibarraSize22{
    font-family: 'Ibarra', serif !important;
    color: black;
    font-size: 2vw;
    letter-spacing: 4.6px;
}
.ibarra{
    font-family: 'Ibarra', serif !important;
    letter-spacing: 4.6px;
}

.playfair_Display {
    font-family: 'Playfair_Display', serif !important;
}

@font-face {
    font-family: 'CormorantGaramond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-Light.ttf') format("truetype");
}
@font-face {
    font-family: 'Ibarra';
    src: url('./fonts/Ibarra_Real_Nova/IbarraRealNova-Regular.ttf') format("truetype");
}
@font-face {
    font-family: 'Playfair_Display';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf') format("truetype");
}

@font-face {
    font-family: "Geometria";
    src: url("https://gymnastjs.github.io/gymnast/fonts/Geometria-Light.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

.btn-secondary {
    padding: 0 0;
    background-color: transparent;
    border: 0
}
